<template>
  <div class="page page-xdiffusion-all-ratios">
    <PageHeader :icon="$t('RATIO.ICON')" :title="$tc('RATIO.TITLES', 3)">
      <template v-slot:nav>
        <!-- <v-tabs
          background-color="transparent"
          height="40px"
          optional
          slider-color="accent"
          slider-size="2"
        >
          <v-tab small :to="{ path: '/xdiffusion/ratios' }">
            <v-icon size="16" class="mr-2">{{
              $t("HEADER.OVERVIEW_ICON")
            }}</v-icon>
            {{ $t("HEADER.OVERVIEW") }}
          </v-tab>
        </v-tabs> -->
      </template>
    </PageHeader>

      <div class="pa-6 scroll position-relative">
        <Loading :value="loading" color="#f0f0f0" />


        <v-expansion-panels flat>
          <v-expansion-panel>

            <v-expansion-panel-header>
              <span>Sélectionnez vos filtres</span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row class="ma-0">
                <v-col cols="12" md="12" lg="4">
                  <div class="pa-0 pb-4">
                    <v-select
                      flat
                      outlined
                      ref="where.campaign_id"
                      hide-details
                      multiple
                      clearable
                      :items="campaignItems"
                      item-text="title"
                      item-value="id"
                      :prepend-inner-icon="$t('CAMPAIGN.ICON')"
                      :label="$t('RATIO.CAMPAIGN')"
                      @change="applyFilters()"
                    />
                  </div>
                  <div class="pa-0 pb-4">
                    <v-select
                      flat
                      outlined
                      ref="where.location_department_code"
                      hide-details
                      multiple
                      clearable
                      item-text="name"
                      item-value="code"
                      :items="locationDepartmentItems"
                      prepend-inner-icon="mdi-map-marker"
                      :label="$t('RATIO.LOCATION')"
                      @change="applyFilters()"
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <div class="pa-0 pb-4">
                    <v-select
                      flat
                      outlined
                      ref="where.experience_level"
                      hide-details
                      multiple
                      clearable
                      :items="experienceItems"
                      prepend-inner-icon="mdi-trophy-variant"
                      :label="$t('RATIO.EXPERIENCE')"
                      @change="applyFilters()"
                    />
                  </div>
                  <div class="pa-0 pb-4">
                    <v-select
                      flat
                      outlined
                      ref="where.xtramile_occupation_id"
                      hide-details
                      multiple
                      clearable
                      :items="occupationItems"
                      item-text="text"
                      item-value="value"
                      :prepend-inner-icon="$t('JOB.CATEGORY_ICON')"
                      :label="$t('RATIO.OCCUPATIONS')"
                      @change="applyFilters()"
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <div class="pa-0 pb-4">
                    <v-select
                      flat
                      outlined
                      ref="where.channel_subscription_id"
                      hide-details
                      clearable
                      :items="channelSubscriptionItems"
                      item-text="channel.title"
                      item-value="id"
                      :prepend-inner-icon="$t('CHANNEL.ICON')"
                      :label="$t('CHANNEL.TITLES')"
                      @change="applyFilters()"
                    />
                  </div>
                  <div class="pa-0 pb-4">
                    <v-select
                      flat
                      outlined
                      hide-details
                      multiple
                      :disabled="true"
                      clearable
                      :items="[]"
                      item-text="channel.title"
                      item-value="id"
                      :prepend-inner-icon="$t('CREATIVE.ICON')"
                      :label="$t('CREATIVE.TITLES')"
                      @change="applyFilters()"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>

          </v-expansion-panel>
        </v-expansion-panels>


        <v-toolbar color="transparent" flat dense class="mt-4">
          <v-toolbar-title>
            {{$t('RATIO.RESULTS')}}
          </v-toolbar-title>
          <v-spacer />

          <v-btn color="secondary black--text" small depressed rounded class="mr-3" @click="exportPDF">
            <v-icon class="mr-3">mdi-download</v-icon>
            {{$t('RATIO.EXPORT_PDF')}}
          </v-btn>

          <v-btn color="secondary black--text" small depressed rounded @click="exportCSV">
            <v-icon class="mr-3">mdi-download</v-icon>
            {{$t('RATIO.EXPORT_EXCEL')}}
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-row class="ma-4 white rounded" ref="pdf-section-1">

          <v-col cols="6" md="6" lg="3" class="pr-10">
            <div class="text-left muted"><small>Budget {{$t("RATIO.USED_TOTAL")}}</small></div>
            <div class="d-flex justify-center mt-2">
              <v-progress-circular :size="160" :width="8" :value="budget_percent" color="accent">
                <div class="text-center primary--text text-h6">
                  <div class="accent--text"><strong class="numeric">{{(values.budget_used || 0).toFixed(2)}} €</strong></div>
                  <v-divider class="mt-1 mb-1" />


                  <v-tooltip top max-width="250px" v-if="values.budget_no_limit">
                    <template v-slot:activator="{ on, attrs }">
                      <strong
                      class="numeric"
                      v-bind="attrs"
                      v-on="on"
                      >{{ $t('CHANNEL_SUBSCRIPTION.BUDGET_OPEN') }}*</strong>
                    </template>
                    {{ $t('CHANNEL_SUBSCRIPTION.BUDGET_OPEN_TEXT') }}
                  </v-tooltip>

                  <div class="numeric" v-else>{{(values.budget || 0).toFixed(2)}} €</div>


                </div>
              </v-progress-circular>
            </div>
          </v-col>

          <v-divider vertical />
          <v-col cols="6" md="6" lg="3" class="d-flex flex-column">
            <div class="text-left muted"><small>{{$t("RATIO.JOBS")}} {{$t("RATIO.CLICKED_TOTAL")}}</small></div>
            <div class="d-flex justify-center flex-grow align-center">
              <div class="text-center primary--text text-h5 width-60 pa-6">
                <strong class="numeric">{{ (values.job_count || 0).toLocaleString() }}</strong>
                <v-divider class="mt-1 mb-1" />
                <div class="numeric">{{ (values.job_count_total || 0).toLocaleString() }}</div>
              </div>
            </div>
          </v-col>

          <v-divider vertical />
          <v-col cols="6" md="6" lg="3" class="d-flex flex-column">
            <div class="text-left muted"><small>{{$t("RATIO.PUBLICATIONS")}} {{$t("RATIO.CLICKED_TOTAL")}}</small></div>
            <div class="d-flex justify-center flex-grow align-center">
              <div class="text-center primary--text text-h5 width-60 pa-6">
                <strong class="numeric">{{ (values.publication_count || 0).toLocaleString() }}</strong>
                <v-divider class="mt-1 mb-1" />
                <div class="numeric">{{ (values.publication_count_total || 0).toLocaleString() }}</div>
              </div>
            </div>
          </v-col>

          <v-divider vertical />
          <v-col cols="6" md="6" lg="3" class="d-flex flex-column">
            <div class="text-left muted"><small>{{$t("RATIO.AUDIENCES")}}</small></div>
            <div class="d-flex justify-center flex-grow align-center">
              <div class="text-center primary--text text-h4 width-60 pa-6">
                <strong class="numeric">{{ (values.audience_count || 0).toLocaleString() }}</strong>
              </div>
            </div>
          </v-col>


        </v-row>


        <v-row class="ma-2" ref="pdf-section-2">
          <v-col cols="6" md="6" lg="3" class="pa-2">
            <div class="pa-6 rounded blue-gradient white--text ">
              <div class="numeric mt-2 text-ellipsis"><strong>{{(values.credit || 0).toLocaleString()}}</strong> <span>{{$t('RATIO.SHORT_CREDIT')}}</span></div>
              <div class="text-h4 mb-4"><small class="numeric"><strong>{{ (values.cost_credit_unit || 0).toFixed(2) }}</strong> <small>€</small></small></div>
              <div class="d-flex">
                <strong>{{$t('RATIO.CREDITS')}}</strong>
                <v-spacer />
                <v-btn small depressed rounded color="primary" @click="historySelected = 'cost_credit'">{{$t('RATIO.SHOW_STATS')}}</v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="6" md="6" lg="3" class="pa-2">
            <div class="pa-6 rounded blue2-gradient white--text ">
              <div class="numeric mt-2 text-ellipsis"><strong>{{(values.click || 0).toLocaleString()}}</strong> <span>{{$t('RATIO.SHORT_CLICK')}}</span></div>
              <div class="text-h4 mb-4"><small class="numeric"><strong>{{ (values.cost_cpc_unit || 0).toFixed(2) }}</strong> <small>€</small></small></div>
              <div class="d-flex">
                <strong>CPC</strong>
                <v-spacer />
                <v-btn small depressed rounded color="primary" @click="historySelected = 'cost_cpc'">{{$t('RATIO.SHOW_STATS')}}</v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="6" md="6" lg="3" class="pa-2">
            <div class="pa-6 rounded blue3-gradient white--text">
              <div class="numeric mt-2 text-ellipsis"><strong>{{(values.view || 0).toLocaleString()}}</strong> <span>{{$t('RATIO.SHORT_VIEW')}}</span></div>
              <div class="text-h4 mb-4"><small class="numeric"><strong>{{ (values.cost_cpm_unit || 0).toFixed(2) }}</strong> <small>€</small></small></div>
              <div class="d-flex">
                <strong>CPM</strong>
                <v-spacer />
                <v-btn small depressed rounded color="primary" @click="historySelected = 'cost_cpm'">{{$t('RATIO.SHOW_STATS')}}</v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="6" md="6" lg="3" class="pa-2">
            <div class="pa-6 rounded yellow-gradient white--text ">
              <div class="numeric mt-2 text-ellipsis"><strong>{{(values.apply || 0).toLocaleString()}}</strong> <span>{{$t('RATIO.SHORT_APPLY')}}</span></div>
              <div class="text-h4 mb-4"><small class="numeric"><strong>{{ (values.cost_cpa_unit || 0).toFixed(2) }}</strong> <small>€</small></small></div>
              <div class="d-flex">
                <strong>CPA</strong>
                <v-spacer />
                <v-btn small depressed rounded color="accent" @click="historySelected = 'cost_cpa'">{{$t('RATIO.SHOW_STATS')}}</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>


        <v-row class="ma-4 white rounded" ref="pdf-section-3">
          <v-col cols="12">
            <v-toolbar color="transparent" flat dense>
              <v-toolbar-title>
                {{historyKeys[historySelected].title}}
              </v-toolbar-title>
              <v-spacer />
              <!-- <v-btn depressed rounded color="secondary black--text" small><v-icon class="mr-2">mdi-download</v-icon>{{$t('RATIO.EXPORT_CSV')}}</v-btn> -->
            </v-toolbar>
            <chart-component
              class="height-50"
              :key="updateKey"
              :loading="historyLoading"
              type="line"
              :values="lineChartData"
            />
          </v-col>
        </v-row>

        <v-toolbar color="transparent" flat dense class="mt-4">

          <v-spacer />

          <v-btn color="secondary black--text" small depressed rounded class="mr-3" @click="exportPDF">
            <v-icon class="mr-3">mdi-download</v-icon>
            {{$t('RATIO.EXPORT_PDF')}}
          </v-btn>

          <v-btn color="secondary black--text" small depressed rounded @click="exportCSV">
            <v-icon class="mr-3">mdi-download</v-icon>
            {{$t('RATIO.EXPORT_EXCEL')}}
          </v-btn>
        </v-toolbar>

      </div>

  </div>
</template>


<style>
v-data-table >>> div > table {
  border-spacing: 0 0.5rem;
}

.items-data-table {
  background-color: #f2f2f2;
}

.small-label {
  color: #868686;
  font-size: 18px;
}

.tile-card {
  height: 125px;
  border-radius: 13px;
}

.green-gradient {
  background: linear-gradient(45deg, #3bd65d, #8ff99a);
}

.blue-gradient {
  background: linear-gradient(45deg, #221e51, #5d57a9);
}

.blue2-gradient {
  background: linear-gradient(45deg, #18153a, #44407a);
}

.blue3-gradient {
  background: linear-gradient(45deg, #100e25, #2f2c55);
}

.red-gradient {
  background: linear-gradient(45deg, #e55643, #f9958f);
}

.yellow-gradient {
  background: linear-gradient(45deg, #e4c937, #f9e28f);
}

.v-progress-linear {
  margin: 0;
  border-radius: 15;
}

.budget-progress-bar {
  position: relative;
  top: 0px;
}

.start {
  text-align: left;
  left: -25%;
}

.end {
  text-align: right;
  right: -25%;
}

.small-card-title {
  color: #211981;
}

.budget-progress-bar .value-budget {
  font-weight: 800;
  font-size: 24px;
  color: #211981;
}

.budget-progress-bar .label-budget {
  font-weight: 400;
  font-size: 14px;
  color: #607096;
}

.ratios-header {
}

.ratios-header .v-input__control {
  background: #fff;
}
.ratios-header .v-select__slot {
}

.ratios-header .v-select__selections {
  display: flex;
  flex-wrap: nowrap;
  max-width: calc(100% - 100px);
  overflow: hidden;
}
.ratios-header .v-select__selection {
  display: inline;
  min-width: 30px;
}
.ratios-content {
  flex-grow: 1;
}

.ratios-tile {
  position: relative;
  background: #fff;
}

.ratios-title-corner {
  position: absolute;
  top: 5px;
  right: 5px;
}

.ratios-filters {
  height: 50vh;
  min-width: 300px;
}
</style>

<script>
import PageHeader from "@/components/PageHeader"
import ChartComponent from "../../../components/charts/Chart.vue"
import Loading from "@/components/Loading"
import jsPDF from 'jspdf'
import domtoimage from 'dom-to-image'

export default {
  name: "PageXdiffusionRatiosOverview",
  components: {
    PageHeader,
    ChartComponent,
    Loading,
  },
  data: (_this) => ({
    loading: false,
    menu: false,
    channelSubscriptionStats: [],
    channelSubscriptionItems: [],
    locationDepartmentItems: [],
    campaignItems: [],
    occupationItems: _this.$i18n.t("JOB.XTRAMILE_OCCUPATIONS_ITEMS"),
    experienceItems: _this.$i18n.t("JOB.EXPERIENCE_LEVEL_ITEMS"),
    channel_subscription_id: [],
    values: {},
    ga4Values: {},
    panel: 0,
    channelMethod: "organic",
    lineChartData: [
      {
        color: "#333154",
        label: _this.$i18n.t("RATIO.CPC"),
        data: [],
      },
    ],
    pieChartData: [
      { value: 37, color: "#333154", label: "test" },
      { value: 63, color: "#EFD663", label: "test 1" },
    ],

    updateKey: 0,
    historyData: [],
    historyLoading: false,
    historySelected: "cost_cpc",
    // historyKeys: {
    //   cost_cpa: { value: "cost_per_acquisition", title: _this.i18n.t('CPA_TITLE') },
    //   cost_cpc: { value: "cost_cpc", title: _this.i18n.t('CPC_TITLE') },
    //   cost_cpm: { value: "cost_cpm", title: _this.i18n.t('CPM_TITLE') },
    //
    //   budget_used: { value: "budget_used", title: _this.i18n.t('BUDGET_USED_TITLE') },
    // },
    historyKeys: {
      cost_cpa: { value: "cost", title: _this.$i18n.t("RATIO.CPA_TITLE") },
      cost_cpc: { value: "cost_cpc", title: _this.$i18n.t("RATIO.CPC_TITLE") },
      cost_cpm: { value: "cost_cpm", title: _this.$i18n.t("RATIO.CPM_TITLE") },
      cost_credit: {
        value: "cost_credit",
        title: _this.$i18n.t("RATIO.CREDITS_TITLE"),
      },

      budget_used: {
        value: "budget_used",
        title: _this.$i18n.t("RATIO.BUDGET_USED_TITLE"),
      },
    },

    // channels data
    tableHeader: [
      {
        text: _this.$i18n.t("RATIO.CHANNEL"),
        align: "start",
        value: "title",
        class: "font-weight-bold",
        cellClass: "font-weight-bold",
        // divider: true,
      },
      {
        text: _this.$i18n.t("RATIO.CPA"),
        value: "cost_cpa_unit",
        class: "font-weight-bold success--text",
        cellClass: "font-weight-bold text-nowrap success--text",
      },
      {
        text: _this.$i18n.t("RATIO.COST"),
        value: "cost",
        class: "font-weight-bold",
        cellClass: "font-weight-bold text-nowrap",
        // divider: true,
      },
      {
        text: _this.$i18n.t("RATIO.PUBLICATIONS"),
        value: "publication_count",
      },
      {
        text: _this.$i18n.t("RATIO.VIEWS"),
        value: "view",
      },
      {
        text: _this.$i18n.t("RATIO.CLICKS"),
        value: "click",
      },
      {
        text: _this.$i18n.t("RATIO.APPLICATIONS"),
        value: "apply",
      },
    ],
  }),

  computed: {
    budget_percent () {
      const v1 = this.values.budget || 0
      const v2 = this.values.budget_used || 0
      return (v2 / v1) * 100
    },
    budget_total () {
      const v = this.values.budget || 0
      return v.toLocaleString() + '.' + v.toFixed(2).split('.')[1]
    },
    budget_used () {
      const v = this.values.budget_used || 0
      return v.toLocaleString() + '.' + v.toFixed(2).split('.')[1]
    },
    budget_remaining () {
      const v = (this.values.budget || 0) - (this.values.budget_used || 0)
      return v.toLocaleString() + '.' + v.toFixed(2).split('.')[1]
    },
  },

  mounted() {
    this.loading = true
    this.loadChannelSubscriptionItems(),
    this.loadCampaignItems(),
    this.loadLocationDepartmentItems()
    this.loadStats()
  },

  watch: {
    historySelected() {
      this.loadHistoryStats()
    },
    historyData() {
      this.formatHistory()
    },
  },

  methods: {

    exportCSV() {
      this.loading = true
      this.$services.api_programmatic.statistics.csv_export_blob()
        .then(response => {
          const blob = new Blob([response], {type: 'text/csv'})
          const downloadUrl = URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = downloadUrl
          const timestamp = new Date().toISOString().substring(0, 19).replace(/[-T:]/g, '_')
          a.download = `export_${timestamp}.csv`
          document.body.appendChild(a)
          a.click()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    async exportPDF() {

      this.loading = true
      const doc = new jsPDF({orientation: 'landscape'})

      const getScaledElementImage = async (el, scale) => {
        let props = {}
        if (scale) {
          props = {
            width: el.clientWidth * scale,
            height: el.clientHeight * scale,
            style: {
              'transform': 'scale('+scale+')',
              'transform-origin': 'top left'
            }
          }
        }
        return await domtoimage.toPng(el, props);
      }

      const el1 = this.$refs['pdf-section-1'], r1 = el1.clientHeight / el1.clientWidth
      const el2 = this.$refs['pdf-section-2'], r2 = el2.clientHeight / el2.clientWidth
      const el3 = this.$refs['pdf-section-3'], r3 = el3.clientHeight / el3.clientWidth
      // For high-quality images, we could push the scale parameter to 2, 4 ...
      const dataUri1 = await getScaledElementImage(el1, 1)
      const dataUri2 = await getScaledElementImage(el2, 1)
      const dataUri3 = await getScaledElementImage(el3, 1)
      const w = doc.internal.pageSize.getWidth(), h1 = r1 * w, h2 = r2 * w, h3 = r3 * w
      doc.setFillColor('#eaeaea')
      doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), 'F')
      doc.addImage(dataUri1, 'PNG', 5, 10, w - 10, h1, null, 'BEST')
      doc.addImage(dataUri2, 'PNG', 5, 60, w - 10, h2, null, 'BEST')
      doc.addImage(dataUri3, 'PNG', 5, 120, w - 10, h3, null, 'BEST')
      const timestamp = new Date().toISOString().substring(0, 19).replace(/[-T:]/g, '_')
      doc.save(`export_${timestamp}.pdf`)

      this.loading = false
    },

    selectAllChannelSubscriptions() {
      if (
        this.channel_subscription_id.length ===
        this.channelSubscriptionItems.length
      ) {
        this.channel_subscription_id = []
      } else {
        this.channel_subscription_id = this.channelSubscriptionItems.map(v => v.id)
      }
    },

    countFilters() {
      if (this.$refs.filters) {
        return this.$refs.filters.inputs.filter(v => v.computedCounterValue).length
      }
      return 0
    },

    clearFilters() {
      this.$refs.filters.reset()
      this.loadStats()
    },

    applyFilters() {
      this.loadStats()
    },

    getImage(item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.controller_name.toLowerCase()}.png`
    },

    formatHistory() {
      if (!this.historyData) return
      const type = this.historySelected
      const key = this.historyKeys[type]
      this.lineChartData[0].label = key.title
      this.lineChartData[0].color = "#333154"
      this.lineChartData[0].unit = "€"

      this.lineChartData[0].data = []
      const uniqueStartDates = [...new Set(this.historyData.map(p => p.start_date))].sort()
      // The API call returns multiple publication stats per day, we sum them to display the total on the graph
      for (const startDate of uniqueStartDates) {
        const p_agg = this.historyData.filter(p => p.start_date === startDate)
          .reduce((p1, p2) => { p1.value += p2.value; return p1 })

        // const formattedDate = new Date(p_agg.start_date).toLocaleDateString("fr-FR", {
        //       year: "numeric",
        //       month: "short",
        //       day: 'numeric',
        //     });
      // formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1),
        this.lineChartData[0].data.push({
          date: p_agg.start_date,
          value: p_agg.value,
          name: key.title
        })
      }

      this.lineChartData[0].data = this.lineChartData[0].data.slice(this.lineChartData[0].data.length - 9, this.lineChartData[0].data.length)

      // this.historyData.map((v) => ({
      //   date: v.start_date,
      //   value: v.value,
      //   name: key.title,
      // }));
    },

    loadLocationDepartmentItems() {
      return this.$services.api_programmatic.location_department
        .search()
        .then(response => {
          if (response.data) {
            this.locationDepartmentItems = response.data
          }
        })
        .catch(e => {
          console.error(e)
        })
    },

    loadChannelSubscriptionStats() {
      return this.$services.api_programmatic.statistics
        .channels()
        .then(response => {
          if (response.data) {
            this.channelSubscriptionStats = response.data
          }
        })
        .catch(e => {
          console.error(e)
        })
    },

    loadChannelSubscriptionItems() {
      return this.$services.api_programmatic.channel_subscription
        .search({
          where: {
            status: [1],
            channel: true,
            contracts: true,
          },
          options: {
            attributes: ['id', 'channel_id']
          }
        })
        .then(response => {
          this.channelSubscriptionItems = response.data
        })
        .catch(e => {
          console.error(e)
        })
    },

    loadCampaignItems () {
      return this.$services.api_programmatic.campaign.search({
        where: {
          status: [1]
        },
        options: {
          order: [['updated_at', 'DESC']],
        }
      })
        .then(response => {
          this.campaignItems = response.data
          if (this.campaignItems && this.campaignItems.length > 0) {
            this.$refs['where.campaign_id'].$data.lazyValue = this.campaignItems[0].id // set initial campaign
            this.loadGA4Stats() // load the GA4 stats only for the initial campaign right now
          }
        })
        .catch(e => {
          console.error(e)
        })
    },

    loadStats() {
      this.$queryFilters().save()
      this.loadGeneralStats()
      this.loadHistoryStats()
    },

    loadHistoryStats(options = {}) {
      this.historyLoading = true;
      const defaultOptions = {}
      const query = this.$queryFormat({
        where: { event: [this.historySelected] },
        options: Object.assign({}, defaultOptions, options),
      })

      this.$services.api_programmatic.statistics.history(query)
        .then(response => {
          if (response.data) {
            this.historyData = response.data
          }
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
        })
        .finally(() => {
          this.historyLoading = false
        })
    },

    loadGeneralStats (options = {}) {
      this.loading = true
      const defaultOptions = {}
      const query = this.$queryFormat({
        options: Object.assign({}, defaultOptions, options),
      })

      this.$services.api_programmatic.statistics.general(query)
        .then(response => {
          if (response.data) {
            this.values = response.data
            // TODO
            // if subscriptions have no_limit_budget, show OPEN in planned budget
            const subIds = query.where.channel_subscription_id || []
            this.values.budget_no_limit = false
            for (let sub of this.channelSubscriptionItems) {
              if (
                (sub.contracts.map(v => v.no_limit_value).indexOf(true) === -1) &&
                (
                  (
                    subIds.length > 0
                    && subIds.indexOf(sub.id) !== -1
                  )
                  ||
                  subIds.length === 0
                )
              ) {
                this.values.budget_no_limit = true
              }
            }

          }
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadGA4Stats (options = {}) {
      this.loading = true
      const defaultOptions = {}
      const query = this.$queryFormat({
        options: Object.assign({}, defaultOptions, options),
      })

      this.$services.api_programmatic.statistics.ga_import(query)
        .then(response => {
          if (response.data) {
            this.ga4Values = response.data
            // If we have some Google Analytics data returned for this campaign
            if (this.ga4Values.total !== undefined) {
              let msgChunks = [`Total ${this.ga4Values.total}`]
              this.ga4Values.channelApplications.forEach(entry => {
                let channelSub = this.channelSubscriptionItems.find(item => item.channel_id === entry.channel_id)
                if (channelSub && channelSub.channel) {
                  msgChunks.push(`${entry.count} via ${channelSub.channel.title}`)
                }
              })
              this.$store.dispatch('messenger/add', {
                type: 'primary',
                code: 'GA4_IMPORT',
                message: msgChunks.join(', '),
                time: 10000
              })
            }
          }
          this.loadStats()
          this.loadChannelSubscriptionStats()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
